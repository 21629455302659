import { ColumnBodyOptions } from "primereact/column";
import PmrE2EProjectDetailsOrderlineModel from "../../shared/interfaces/pmr-e2e-project-details-orderline-model";
import PmrE2EProjectModel from "../../shared/interfaces/pmr-e2e-project-model";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Button } from "primereact/button";
import classes from "./InvoicePriceTemplate.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCoins,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import { Field, FieldProps, Form, FormikProvider, useFormik } from "formik";
import PmrMultiSelectCurrencies from "../../shared/components/pmr-multiselect-currencies/PmrMultiSelectCurrencies";
import CurrencyService from "../../../../services/CurrencyService";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrencies,
  selectRequireInvoicePrices,
  updateRefreshInvoiceWarnings,
  updateRequireInvoicePrices,
} from "../../../../features/projectManagementPmr/projectManagementPmrSlice";
import * as yup from "yup";
import numeral from "numeral";
import { updateOrderLineInvoice } from "../../../../features/projectManagement/projectManagementSlice";
import UpdateProjectlineCurrencyModel from "../../../../shared/models/currency-models/UpdateProjectlineCurrencyModel";
import useToastr from "../../../../hooks/useToastr";
import InvoiceWarningIcon from "../../shared/components/invoice-warning-icon/InvoiceWarningIcon";
import ProjectOrderlineStatus from "../../shared/enums/project-orderline-status";
import { removeAutofill } from "../../../../utils/helpers/input.helpers";

interface InvoicePriceTemplateProps {
  rowData: PmrE2EProjectDetailsOrderlineModel;
  project: PmrE2EProjectModel;
  col: ColumnBodyOptions;
}

export interface InvoicePriceTemplateInput {
  invoicePriceAmount?: number | null;
  invoicePriceCurrency?: string | null;
}

export const invoicePriceValidationSchema: yup.SchemaOf<InvoicePriceTemplateInput> =
  yup.object().shape({
    invoicePriceAmount: yup.number().min(0.01).required(),
    invoicePriceCurrency: yup.string().required(),
  });

export const ulPayment = "UL: Payment";

export const defaultInvoicePriceAmountProps: InputNumberProps = {
  maxFractionDigits: 2,
  minFractionDigits: 2,
  allowEmpty: true,
  autoFocus: true,
  placeholder: "0.00",
  mode: "decimal",
  useGrouping: false,
};

export const InputNumberAutoFillSync = ({ inputChange, ...props }: InputNumberProps & {
  inputChange?: (event: Event) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!inputChange) return;

    inputRef.current.addEventListener("input", inputChange);
  }, [inputRef.current]);

  return <InputNumber
    {...props}
    {...defaultInvoicePriceAmountProps}
    inputRef={inputRef}
  />
}

export default function InvoicePriceTemplate({
  rowData,
  project,
  col,
}: InvoicePriceTemplateProps) {
  const requireInvoicePrices = useSelector(selectRequireInvoicePrices);
  const requireInvoicePrice = requireInvoicePrices.find(
    (item) =>
      item.projectId === project?.id &&
      item.lines.some((line) => line.id === rowData.id)
  );
  const requireInvoiceLine = requireInvoicePrice?.lines?.find(line => line.id === rowData.id);

  const hasWarning = !!requireInvoicePrice;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currencies = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const { showSuccess } = useToastr();

  const buttonDefaultClasses = "p-button-rounded p-button-text p-button-sm";
  const warningTitle = "The FLEX Task associated with the ‘UL: Payment’ milestone for this order line was completed in FLEX without an Invoice Price. Enter an Invoice price to fix the discrepancy.";

  const successMessage = () => (
    <>
      The invoice price has been successfully saved for Order Line{" "}
      <b>{rowData.orderLineNumber}</b>.
    </>
  );
  const onSubmit = async (values: InvoicePriceTemplateInput) => {
    if (!formik.isValid) return;
    setIsSubmitting(true);
    try {
      if (!selectedCurrency) {
        console.warn("Currency not found");
        return;
      }

      const request: UpdateProjectlineCurrencyModel = {
        e2eProjectLineId: rowData.id,
        invoicePriceAmount: values.invoicePriceAmount ?? null,
        invoiceCurrencyCode: selectedCurrency?.code,
        invoiceCurrencyId: selectedCurrency.id,
      };
      await CurrencyService.updateProjectlineCurrency([request]);
      dispatch(updateOrderLineInvoice([request]));
      dispatch(updateRefreshInvoiceWarnings(true));
      setIsEditMode(false);
      showSuccess("Invoice Price Saved", "", successMessage);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const defaultCurrency = currencies.find(
    (item) => item.code === rowData.currency
  );
  const formik = useFormik<InvoicePriceTemplateInput>({
    initialValues: {
      invoicePriceAmount: rowData.invoicePriceAmount,
      invoicePriceCurrency: rowData.invoiceCurrencyId ?? defaultCurrency?.id,
    },
    onSubmit,
    validationSchema: invoicePriceValidationSchema,
    enableReinitialize: true,
  });

  const selectedCurrency = currencies.find(
    (item) => item.id === formik.values?.invoicePriceCurrency
  );

  const handleEdit = () => {
    formik.resetForm();
    setIsEditMode(true);
  };

  useEffect(() => {
    if (!requireInvoiceLine?.isEditMode) return;

    setIsEditMode(true);
    dispatch(updateRequireInvoicePrices(requireInvoicePrices.map(item => {
      if (item.projectId !== project.id) return item;

      return {
        ...item,
        lines: item.lines.map(line => {
          return {
            ...line,
            isEditMode: false
          }
        })
      }
    })));
  }, [requireInvoiceLine]);

  useEffect(() => {
    formik.validateForm();
  }, []);

  if (
    !project.e2EProjectLineMilestoneGroupItems.some(
      (milestone) =>
        milestone.name === ulPayment &&
        rowData.id === milestone.e2EProjectLineId
    )
  )
    return <>N/A</>;

  if (!isEditMode) {
    const display = `${numeral(formik.values.invoicePriceAmount).format(
      "0.00"
    )} ${selectedCurrency?.code}`;
    const hasInvoicePriceAmount = formik.values.invoicePriceAmount || formik.values.invoicePriceAmount === 0;
    return (
      <div
        key={display}
        className={clsx("cursor-pointer", classes["invoice-button"], rowData.orderLineStatus === ProjectOrderlineStatus.Completed && classes["completed"])}
        onClick={handleEdit}
        title={
          hasWarning
            ? warningTitle
            : "Click to enter Invoice Amount and set Invoice Currency"
        }
      >
        {(() => {
          if (hasInvoicePriceAmount) {
            return display;
          } else if (!hasWarning && !hasInvoicePriceAmount) {
            return <FontAwesomeIcon icon={faCoins} />;
          }
          else if (hasWarning) {
            return <InvoiceWarningIcon />
          }
        })()}
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form className={clsx(classes["invoice-form"], "invoice-price-template")}>
        {hasWarning && <InvoiceWarningIcon title={warningTitle} />}
        <Field name="invoicePriceAmount">
          {({ field, meta, form }: FieldProps) => (
            <InputNumberAutoFillSync
              {...field}
              onChange={(event) =>
                form.getFieldHelpers(field.name).setValue(event.value)
              }
              inputChange={field.onChange}
              style={{ width: "120px" }}
              inputId={field.name}
              className={clsx(meta.error && "p-invalid")}
              disabled={isSubmitting}
            />
          )}
        </Field>

        <Field name="invoicePriceCurrency">
          {({ field, meta }: FieldProps) => (
            <PmrMultiSelectCurrencies
              {...field}
              style={{
                width: "180px",
              }}
              className={clsx(meta.error && "p-invalid")}
              disabled={isSubmitting}
            />
          )}
        </Field>

        <div className={classes["button-group"]}>
          <Button
            disabled={!formik.isValid || !formik.dirty}
            type="submit"
            icon={<FontAwesomeIcon icon={faCheck} />}
            className={buttonDefaultClasses}
            loading={isSubmitting}
          />
          {!isSubmitting && (
            <Button
              type="button"
              icon={<FontAwesomeIcon icon={faTimes} />}
              className={buttonDefaultClasses}
              onClick={() => {
                formik.resetForm();
                setIsEditMode(false);
              }}
            />
          )}
        </div>
      </Form>
    </FormikProvider>
  );
}
